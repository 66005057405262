@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.title {
    margin: 0 auto;
    margin-bottom: @magritte-core-space-6-x;

    .screen-gt-s({
        margin-bottom: @magritte-core-space-8-x;
        width: 600px;
    });
}

.list {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: @magritte-core-space-6-x;

    margin-bottom: @magritte-core-space-6-x;

    .screen-gt-s({
        grid-template-columns: repeat(3, 1fr);
    });

    .screen-gt-l({
        grid-template-columns: repeat(4, 1fr);
    });
}
