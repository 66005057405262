@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';
@import 'src/styles/font-title-secondary';
@import 'src/styles/card-helpers';
@import 'src/pages/Index/styles';
@import 'src/styles/common';

@height-breakpoint-m: 768px;
@height-breakpoint-l: 720px;
@height-breakpoint-xl: 1024px;
@height-breakpoint-xxl: 1080px;
@height-screen-gt-s: ~'(min-height: @{height-breakpoint-m})';
@height-screen-gt-m: ~'(min-height: @{height-breakpoint-l})';
@height-screen-gt-l: ~'(min-height: @{height-breakpoint-xl})';
@height-screen-gt-xl: ~'(min-height: @{height-breakpoint-xxl})';

@main-section-gap-mobile: 24px;
@main-section-gap-desktop: 56px;
@grid-layout-margin-xs: 16px;
@grid-layout-margin-s: 56px;

.scalable-background(@screen-gt-x, @height-breakpoint, @background-gap-bottom, @height-screen-gt-x) {
    @media @screen-gt-x {
        background-size: auto @height-breakpoint - @background-gap-bottom;
        background-position: center bottom @background-gap-bottom;

        @media @height-screen-gt-x {
            background-size: auto calc(100% - @background-gap-bottom);
        }
    }
}

.wrapper {
    position: relative;
    z-index: 1;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: @landing-section-gap-mobile-half - @main-section-gap-mobile;

    &::after {
        content: '';
        display: block;
        height: @main-section-gap-mobile;
        flex: 0 0 @main-section-gap-mobile;

        .screen-gt-l({
            height: @main-section-gap-desktop;
            flex: 0 0 @main-section-gap-desktop;
        });
    }

    .screen-gt-s({
        position: static;
        min-height: 100vh;
    });

    .screen-gt-l({
        padding-bottom: @landing-section-gap-desktop-half - @main-section-gap-desktop;
    });
}

.background {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: calc(100% + @grid-layout-margin-xs);
    height: 760px;
    background-image: url('assets/legs.excluded.svg');
    background-repeat: no-repeat;
    background-size: auto 100%;

    .screen-gt-xs({
        width: calc(100% + @grid-layout-margin-s);
        height: 630px;
    });

    .screen-gt-s({
        max-width: 100%;
        width: 100vw;
        height: 100vh;
        justify-content: space-between;
    });

    .scalable-background(@screen-magritte-gt-s, @height-breakpoint-m, 75px, @height-screen-gt-s);
    .scalable-background(@screen-magritte-gt-m, @height-breakpoint-l, 75px, @height-screen-gt-m);
    .scalable-background(@screen-magritte-gt-l, @height-breakpoint-xl, 110px, @height-screen-gt-l);
    .scalable-background(@screen-magritte-gt-xl, @height-breakpoint-xxl, 110px, @height-screen-gt-xl);
}

.main-content {
    margin: 164px 0 140px;
    text-align: center;

    .screen-gt-s({
        margin: @header-height-desktop 0 40px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    });
}

.title {
    .font-title-secondary-2();

    color: @magritte-color-text-primary;

    .screen-gt-s({
        .font-title-secondary-1();
    });

    .screen-gt-l({
        font-size: 80px;
        line-height: 96px;
    });
}

.text-wrapper {
    .screen-gt-s({
        width: 60%;
    });

    .screen-gt-m({
        width: 50%;
    });

    .screen-gt-l({
        width: 70%;
    });

    .screen-gt-xl({
        width: 100%;
    });
}

.text {
    .magritte-typography-paragraph-1-regular();

    color: @magritte-color-text-primary;
    padding: 24px 0 56px;

    .screen-gt-xs({
        font-size: 20px;
        line-height: 32px;
    });

    .screen-gt-l({
        font-size: 36px;
        line-height: 48px;
        padding: 36px 0 62px;
    });
}

.button-row {
    display: flex;
}

.button-col {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: @magritte-core-space-3-x;

    .screen-gt-s({
        flex-direction: row;
    });
}

.footer {
    display: flex;
    gap: @magritte-core-space-2-x;

    .screen-gt-xs({
        margin-left: -@grid-layout-margin-s;
        padding-left: @grid-layout-margin-s;
        margin-right: -@grid-layout-margin-s;

    });

    .screen-gt-s({
        margin-left: 0;
        padding-left: 0;
        margin-right: 0;
        flex-direction: row-reverse;
    });
}

.counter-list {
    padding: 0 @magritte-core-space-4-x;
    display: flex;
    column-gap: @magritte-core-space-2-x;
    overflow-x: auto;
}

.scroll-to-courses-item {
    margin: 0 @magritte-core-space-4-x;
    width: 56px;
}

.scroll-to-courses-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.counter-item {
    margin: 0 @magritte-core-space-4-x;
    flex: 1 0 247px;
}

.counter-item-text {
    margin-top: @magritte-core-space-1-x;
}
