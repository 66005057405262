@import '@hh.ru/magritte-ui/breakpoints';

.container {
    height: auto;

    .screen-gt-s({
    min-height: 180px;
    height: 180px;
  });
}
