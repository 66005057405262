@import '@hh.ru/magritte-ui/tokens';

.list {
    list-style: none;
    color: inherit;
}

.item {
    display: inline;

    &:not(:first-child)::before {
        content: '·';
        display: inline-block;
        padding: 0 @magritte-core-space-1-x;
        color: inherit;
    }
}
