@import '@hh.ru/magritte-ui/tokens';

.footer {
    padding: @magritte-core-space-10-x 0;
}

.footer-nav-group {
    margin-bottom: @magritte-core-space-6-x;
}

.footer-nav-list {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-3-x;
}

.footer-nav-title {
    margin-bottom: @magritte-core-space-3-x;
}

.footer-recommendation-technologies {
    padding-top: @magritte-core-space-4-x;
}

.footer-data-info {
    padding-top: @magritte-core-space-1-x;
}

.footer-copyright {
    margin-top: @magritte-core-space-4-x;
}
