@import '@hh.ru/magritte-ui/tokens';

.container {
    display: flex;
    gap: @magritte-core-space-1-x;

    .icon {
        padding: 1px 0;
        width: 18px;
        height: 18px;
    }
}
