@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.container {
    .magritte-typography-label-3-regular();

    display: flex;
    align-items: center;
    gap: @magritte-core-space-4-x;
    color: @magritte-color-text-secondary;
}

.views {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
