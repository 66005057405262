@font-family-title-secondary: Stapel, Arial, sans-serif;

.font-title-secondary-1() {
    font-family: @font-family-title-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
}

.font-title-secondary-2() {
    font-family: @font-family-title-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
}

.font-title-secondary-3() {
    font-family: @font-family-title-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
}

.font-title-secondary-4() {
    font-family: @font-family-title-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.font-title-secondary-5() {
    font-family: @font-family-title-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}
