@import '@hh.ru/magritte-ui/tokens';

.amount-details {
    display: flex;
    align-items: baseline;
    gap: @magritte-core-space-2-x;
}

.amount {
    .magritte-typography-title-4-semibold();
}

.amount-positive {
    color: @magritte-color-text-positive;
}
