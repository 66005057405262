@import '@hh.ru/magritte-ui/breakpoints';

.spinning-star-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.spinning-star {
    position: absolute;
    height: 2193px;
    width: 2193px;
    left: -1050px;
    bottom: -550px;
    background-image: url('star.svg');
    background-repeat: no-repeat;
    background-position: top right;
    /* stylelint-disable time-min-milliseconds */
    transition: transform 0.01s;

    .screen-gt-s({
        left: -670px;
        bottom: -650px;
    });

    .screen-gt-m({
        left: -750px;
        bottom: -800px;
    });
}
