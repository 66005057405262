@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/base-variables';

.login-banner {
    display: flex;
    flex-direction: column;

    .screen-gt-s({
        flex-direction: row;
    });

    .screen-gt-m({
        flex-direction: row;
    });

    &.login-banner-reverse {
        .screen-gt-s({
            flex-direction: row-reverse;
        });
    }
}

.enter {
    .magritte-typography-title-4-semibold();

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 130px;

    .screen-gt-s({
        height: auto;
        flex: 0 0 40%;
    });

    background-color: @magritte-color-background-accent;
    border-radius: @magritte-static-border-radius-1000;
    color: @magritte-color-component-button-text-accent;

    &:focus,
    &:hover,
    &:active {
        color: @magritte-color-component-button-text-accent;
        outline: none;
    }

    &:hover {
        background-color: @magritte-color-component-button-background-state-accent-hovered;
    }

    &:focus-visible {
        outline: 4px solid @magritte-color-component-button-stroke-state-accent-focused-accessible;
    }
}

.description {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: @magritte-core-space-6-x;
    padding: @magritte-core-space-10-x @magritte-core-space-6-x;

    .screen-gt-s({
        flex-direction: row;
        padding: @magritte-core-space-7-x @magritte-core-space-10-x;
    });
}
