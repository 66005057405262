@import '@hh.ru/magritte-ui/breakpoints';

@landing-section-gap-mobile: 88px;
@landing-section-gap-desktop: 216px;

@landing-section-gap-mobile-half: (@landing-section-gap-mobile / 2);
@landing-section-gap-desktop-half: (@landing-section-gap-desktop / 2);

.page-wrapper {
    position: relative;
}

.page-container {
    position: relative;
    z-index: 1; // чтобы звезда была под контентом
}

.main-container {
    display: flex;
    flex-direction: column;
    gap: @landing-section-gap-mobile;
    padding-bottom: @landing-section-gap-mobile-half;

    .screen-gt-l({
        gap: @landing-section-gap-desktop;
        padding-bottom: @landing-section-gap-desktop-half;
    });
}

.sub-container {
    display: flex;
    flex-direction: column;
    gap: @landing-section-gap-mobile;

    .screen-gt-l({
        gap: @landing-section-gap-desktop-half;
    });
}

.login-banner-wrapper {
    margin-top: -48px; // настраиваем отрицательный отступ т.к. баннер чуть прижат к секции выше (видение дизайна)

    .screen-gt-s({
        margin-top: -24px;
    });

    .screen-gt-m({
        margin-top: 0;
    });
}
