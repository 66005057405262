.multiline_ellipsis(@count: none) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: @count;
    -webkit-box-orient: vertical;
}

.line-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.linebreak() {
    white-space: pre-line;
}
