@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.disclaimer {
    margin-top: @magritte-core-space-4-x;
}

.footer {
    margin-top: @magritte-core-space-10-x;
    display: flex;
    gap: @magritte-core-space-3-x;
    flex-direction: column-reverse;

    .screen-gt-s({
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: @magritte-core-space-8-x;
    });
}

.subtitle {
    margin-bottom: @magritte-core-space-6-x;

    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-2-x;

    .screen-gt-s({
        margin-bottom: @magritte-core-space-4-x;
    });
}
