@import '@hh.ru/magritte-ui/tokens';

.card-course-promo {
    display: inline-flex;
    gap: @magritte-core-space-2-x;
}

.hh-promo-tag {
    display: inline-flex;
    flex-grow: 1;
    align-items: center;

    padding: @magritte-core-space-1-x @magritte-core-space-2-x;
    border-radius: @magritte-core-border-radius-2-x;
    background: @magritte-color-background-primary;
    white-space: nowrap;
}

.hh-promo-tag-special {
    background: @magritte-color-background-special-secondary;
}
