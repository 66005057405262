@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.section-content {
    border-radius: @magritte-static-border-radius-1000;
    background-color: @magritte-color-background-secondary;
    padding: 60px 0 80px;

    .screen-gt-s({
        border-radius: 120px;
    });
}

.img {
    width: 100%;
}

.providers-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: @magritte-core-space-6-x;
    flex-direction: column;

    .screen-gt-s({
        flex-direction: row;
        gap: 66px;
    });
}

.providers-card-text {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
}

.provider-img {
    max-width: 70%;
    height: 48px;

    .screen-gt-s({
        max-width: 30%;
    });
}
