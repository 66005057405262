@import '@hh.ru/magritte-ui/tokens';

.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.description-padding {
    padding-left: @magritte-core-space-6-x;
}

.content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
